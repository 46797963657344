import React, { useState } from 'react';
import Chart from "chart.js";
import Loading from '../components/Loading';
import HomeText from '../components/HomeText';
import SosChart from '../components/Chart';
import SearchBar from '../components/SearchBar';
import ShareOfSearchRetrieval from '../ShareOfSearchRetrieval';

import 'bootstrap';
import './Css/Home.css';
import './Css/Search.css';

const COLOR_ARRAY = [
  '#000000', '#FFB399', '#FF33FF', '#ff6600', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
];

/**
 * Search page component.
 *
 * @returns {React.Component}
 */
const Search = () => {
  const chartRef = React.createRef();
  const areaChartRef = React.createRef();
  const barChartRef = React.createRef();
  const volumeChartRef = React.createRef();

  const [isDataError, setIsDataError] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [chartSubTitle, setChartSubTitle] = useState('');

  const [clientInfo, setClientInfo] = useState([]);

  /**
   * Prepare and create the "Trend in Search Volume" graph
   *
   * @param {string[]} dateArray
   * @param {string[]} valuesArrayVolume2
   * @param {any[]} postData
   * @returns {Chart} - The instantiated chart.js object
   */
  const createMultiVolumeGraph = (dateArray, valuesArrayVolume2, postData) => {
    var combinedValueArray = new Array(valuesArrayVolume2[0].split(",").length);
    for(var x = 0; x < combinedValueArray.length; x++){
      combinedValueArray[x] = [];
    }
    valuesArrayVolume2.forEach((item, i) => {
      var vArray = item.split(",");
      for (var c = 0; c < vArray.length; c++) {
        combinedValueArray[c].push(vArray[c]);
      }
    });

    var nameArray = postData[5];
    nameArray = postData[5].split(",");
    var concludingChart = new Chart(document.getElementById("myVolumeChart"), {
      type: "line",
      data: {
        labels: dateArray,
        datasets: [
          { label: nameArray[0], data: combinedValueArray[0], fill: false, borderColor: COLOR_ARRAY[0] },
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Trend in Search Volume',
              fontSize: 20,
            },
          }]},
        legend: {
          labels: {
            // This more specific font property overrides the global property
            fontColor: 'black',
            fontSize: 15,
            fontStyle: 'bold',
          }
        },
      }
    });
    for(let i = 1; i < nameArray.length; i++){
      concludingChart.data.datasets.push({
        label: nameArray[i],
        fill: false,
        borderColor: COLOR_ARRAY[i],
        data: combinedValueArray[i],
      });
    }

    const country = postData[0];
    const category = postData[3];
    const startdate = postData[1].getDate() + " " + (postData[1].toLocaleString('default', { month: 'long' }).substring(0,3).toUpperCase()) + " " + postData[1].getFullYear();
    const enddate = postData[2].getDate() + " " + (postData[2].toLocaleString('default', { month: 'long' }).substring(0,3).toUpperCase()) + " " + postData[2].getFullYear();

    for(var c = 0; c < dateArray.length; c++){
      dateArray[c] = dateArray[c].replace(',', '');
      dateArray[c] = dateArray[c].replace('\u2013', '-');
    }

    var button = document.createElement("button");
    button.id = "volumeDownloadbutton"
    button.innerHTML = "Download Search Volume";
    button.onclick = function(){
      var namesArray = nameArray;
      var datesArray = dateArray
      var rows = [
        ["INDEX OF SEARCH VOLUME / " + country + " | " + category +
        " | " + startdate +
        " - " + enddate ],
        ["Dates", namesArray[0]]
      ];
      for(let x = 1; x<namesArray.length; x++){
        rows[1].push(namesArray[x]);
      }
      for(let x = 0; x < datesArray.length; x++){
        var arr = valuesArrayVolume2[x].split(",");
        arr.join()
        rows.push(Array.of(datesArray[x], arr));
      }

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "volumeSOS.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    };

    // 2. Append somewhere
    // JMH NOTE: Don't show button. (But maybe we'll still want it later?)
    // Delete later if confident we don't need
    //var downloadContainer = document.getElementById("downloadContainer");
    //downloadContainer.appendChild(button);

    // 3. Add event handler
    concludingChart.update();
    return(concludingChart);
  }

  /**
   * Prepare and create the "Total % Share of Search" graph
   *
   * @param {string[]} valuesArray
   * @param {any[]} postData
   * @param {number} barChartMaxY
   * @returns {Chart} - The instantiated chart.js object
   */
  const createMultiBarGraph = (valuesArray, postData, barChartMaxY) => {
    if(valuesArray.length < 1) {
      setIsDataError(true);
    } else {
      setIsDataError(false);
    };

    var nameArray = postData[5];
    nameArray = postData[5].split(",");
    var ctx = document.getElementById("myBarChart")
    var concludingChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: nameArray,
        datasets: [{
          data: valuesArray,
          backgroundColor: COLOR_ARRAY,
          borderColor: COLOR_ARRAY,
          borderWidth: 1
        }]
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [{
            ticks: {
              fontSize: 20
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: parseInt(barChartMaxY),
            },
            scaleLabel: {
              display: true,
              labelString: 'Total % Share Of Search',
              fontSize: 20
            },
          }]
        },
        legend: {
          display: false,
          labels: {
            // This more specific font property overrides the global property
            display: false,
            fontSize: 0,
          }
        },
      }
    });

    concludingChart.options.animation.onComplete =  function (){
      var ctx = this.chart.ctx;
      // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
      ctx.font = Chart.helpers.fontString(16, "bold", Chart.defaults.global.defaultFontFamily);
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      concludingChart.data.datasets.forEach(function (dataset, i) {
        var meta = concludingChart.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = parseFloat(dataset.data[index]).toFixed(1) + "%";
          ctx.fillText(data, bar._model.x, bar._model.y - 5);
        });
      })
    }
    if(concludingChart.width < 100){
      concludingChart.width = 700;
    }
    concludingChart.update();
    return(concludingChart);
  }

   /**
   * Prepare and create the "Trend in % Share of Search" graph
   *
   * @param {string[]} dateArray
   * @param {string[]} valuesArrayVolume
   * @param {any[]} postData
   * @returns {Chart} - The instantiated chart.js object
   */
  const createMultiPercentGraph = (dateArray, valuesArrayVolume, postData) => {
    var valuesArray = valuesArrayVolume;

    var nameArray = postData[5];
    nameArray = postData[5].split(",");
    let combinedValueArray;

    if (nameArray.length === 1) {
      valuesArray = [];
      valuesArrayVolume.forEach((item, i) => {
        valuesArray.push("100");
      });

      // Use for non perrcentage - graph plot values

      combinedValueArray = new Array(valuesArray[0].split(",").length);
      for(var x = 0; x < combinedValueArray.length; x++){
        combinedValueArray[x] = [];
      }
      valuesArray.forEach((item, i) => {
        var vArray = item.split(",");
        for (var c = 0; c < vArray.length; c++) {
          combinedValueArray[c].push(vArray[c]);
        }
      });
    } else {
      // Graph plot points done as a percentage
      combinedValueArray = new Array(valuesArray[0].split(",").length);
      for(let x = 0; x < combinedValueArray.length; x++){
        combinedValueArray[x] = [];
      }
      if(combinedValueArray.length === 1){
        valuesArray.forEach((item, i) => {
          var vArray = item.split(",");
          for (var c = 0; c < vArray.length; c++) {
            combinedValueArray[c].push(vArray[c]);
          }
        });
      } else {
        valuesArray.forEach((item, i) => {
          var vArray = item.split(",");

          var totalOfAll = 0
          vArray.forEach((item, i) => {
            totalOfAll = totalOfAll + parseInt(item);
          });
          for (var c = 0; c < vArray.length; c++) {
            var percentageOfCurrent = ((parseInt(vArray[c])/totalOfAll) * 100).toFixed(2);
            combinedValueArray[c].push(percentageOfCurrent);
          }
        });
      }
    }

    const shareOfSearchString = ShareOfSearchRetrieval.getShareOfSearchPercentage(nameArray, valuesArray);
    var barChartPercentages = shareOfSearchString.split(":");
    var barChartPercentagesArray = [];
    for(let x = 0; x < barChartPercentages.length; x++){
      var hopefullyPercentage = barChartPercentages[x].substr(0,6)
      hopefullyPercentage = hopefullyPercentage.trim();
      if(!isNaN(parseInt(hopefullyPercentage))){
        barChartPercentagesArray.push(hopefullyPercentage.trim());
      }
    }
    var valueForBarYaxes = 0;
    barChartPercentagesArray.forEach(function (data) {
      if(valueForBarYaxes < data){
        valueForBarYaxes = parseInt(data);
      }
    });

    let barChartMaxY = valueForBarYaxes;
    if((parseInt(barChartMaxY) > 50)){
      if((parseInt(barChartMaxY) + 5)%5 === 0){
        barChartMaxY = (parseInt(barChartMaxY) + 5);
      }
      if (((parseInt(barChartMaxY) + 4)%5) === 0){
        barChartMaxY = (parseInt(barChartMaxY) + 4);
      }
      if (((parseInt(barChartMaxY) + 3)%5) === 0){
        barChartMaxY = (parseInt(barChartMaxY) + 3);
      }
      if (((parseInt(barChartMaxY) + 2)%5) === 0){
        barChartMaxY = (parseInt(barChartMaxY) + 2);
      }
      if (((parseInt(barChartMaxY) + 6)%5) === 0){
        barChartMaxY = (parseInt(barChartMaxY) + 6);
      }
    } else {
      if((parseInt(barChartMaxY) + 2)%2 === 0) {
        barChartMaxY = (parseInt(barChartMaxY) + 2);
      } else {
        barChartMaxY = (parseInt(barChartMaxY) + 3)
      }
    }

    createMultiBarGraph(barChartPercentagesArray, postData, barChartMaxY);
    var concludingChart = new Chart(document.getElementById("myChart"), {
      type: "line",
      data: {
        labels: dateArray,
        datasets: [
          { label: nameArray[0], data: combinedValueArray[0], fill: false, borderColor: COLOR_ARRAY[0] },
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: '% Share of Search',
              fontSize: 20,
            },
          }]
        },
        legend: {
          labels: {
            // This more specific font property overrides the global property
            fontColor: 'black',
            fontSize: 15,
            fontStyle: 'bold',
          }
        },
      }
    });

    for (let i = 1; i < nameArray.length; i++) {
      concludingChart.data.datasets.push({
        label: nameArray[i],
        fill: false,
        borderColor: COLOR_ARRAY[i],
        data: combinedValueArray[i],
      });
    }

    const country = postData[0];
    const category = postData[3];
    const startdate = postData[1].getDate() + " " + (postData[1].toLocaleString('default', { month: 'long' }).substring(0,3).toUpperCase()) + " " + postData[1].getFullYear();
    const enddate = postData[2].getDate() + " " + (postData[2].toLocaleString('default', { month: 'long' }).substring(0,3).toUpperCase()) + " " + postData[2].getFullYear();

    for (var c = 0; c < dateArray.length; c++) {
      dateArray[c] = dateArray[c].replace(',', '');
      dateArray[c] = dateArray[c].replace('\u2013', '-');
    }

    var button = document.createElement("button");
    button.id = "percentageDownloadbutton";
    button.innerHTML = "Download % Share Of Search";
    button.onclick = function() {
      var namesArray = nameArray
      var datesArray = dateArray
      var valuesArray = combinedValueArray

      var cva = [];
      for(let x = 0; x<valuesArray[0].length; x++){
        cva.push([]);
      }
      for(let c = 0; c<valuesArray.length; c++){
        for(let x = 0; x<cva.length; x++){
          cva[x].push(valuesArray[c][x])
        }
      }
      var rows = [
        ["% SHARE OF SEARCH / " + country + " | " + category +
        " | " + startdate +
        " - " + enddate],
        ["Dates", namesArray[0]]
      ];
      for(var x = 1; x<namesArray.length; x++){
        rows[1].push(namesArray[x]);
      }
      for(var c = 0; c<cva.length; c++){
        rows.push(Array.of(datesArray[c], cva[c]));
      }

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "percentageSOS.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    }

    // JMH NOTE: Don't show button. (But maybe we'll still want it later?)
    // Delete later if confident we don't need
    //var downloadContainer = document.getElementById("downloadContainer");
    //downloadContainer.appendChild(button);

    concludingChart.update();
    return(concludingChart);
  }

  /**
   * Prepare and create the "Trend in % Share of Search" graph
   *
   * @param {string[]} dateArray
   * @param {string[]} valuesArray
   * @param {any[]} postData
   * @returns {Chart} - The instantiated chart.js object
   */
  const createMultiAreaGraph = (dateArray, valuesArray, postData) => {
    var nameArray = postData[5];
    nameArray = postData[5].split(",");
    let combinedValueArray;

    // Use for non percentage - graph plot values
    if (nameArray.length === 1) {
      for(var n = 0; n<valuesArray.length; n++){
        valuesArray[n] = "100";
      }

      combinedValueArray = new Array(valuesArray[0].split(",").length);

      for (var x = 0; x < combinedValueArray.length; x++){
        combinedValueArray[x] = [];
      }

      valuesArray.forEach((item, i) => {
        var vArray = item.split(",");
        for (var c = 0; c < vArray.length; c++) {
          combinedValueArray[c].push(vArray[c]);
        }
      });
    } else {
      combinedValueArray = new Array(valuesArray[0].split(",").length);

      for (let x = 0; x < combinedValueArray.length; x++) {
        combinedValueArray[x] = [];
      }

      if (combinedValueArray.length === 1) {
        valuesArray.forEach((item, i) => {
          var vArray = item.split(",");
          for (var c = 0; c < vArray.length; c++) {
            combinedValueArray[c].push(vArray[c]);
          }
        });
      } else {
        valuesArray.forEach((item, i) => {
          var vArray = item.split(",");
          var totalOfAll = 0
          vArray.forEach((item, i) => {
            totalOfAll = totalOfAll + parseInt(item);
          });
          for (var c = 0; c < vArray.length; c++) {
            var percentageOfCurrent = ((parseInt(vArray[c])/totalOfAll) * 100).toFixed(2);
            combinedValueArray[c].push(percentageOfCurrent);
          }
        });
      }
    }

    var concludingChart = new Chart(document.getElementById("myAreaChart"), {
      type: "line",
      data: {
        labels: dateArray,
        datasets: [
          { label: nameArray[0], data: combinedValueArray[0], fill: true, backgroundColor: COLOR_ARRAY[0] },
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        elements: {
          point:{
            radius: 0
          }
        },
        plugins: {
          filler: {
            propagate: true
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100
            },
            scaleLabel: {
              display: true,
              labelString: '% Share of Search',
              fontSize: 20
            },
            stacked: true
          }]
        },
        yAxes: [{
          ticks: {
            beginAtZero: true,
            max: 100
          }
        }],
        legend: {
          labels: {
            // This more specific font property overrides the global property
            fontColor: 'black',
            fontSize: 15,
            fontStyle: 'bold',
          }
        },
      }
    });
    for (var i = 1; i < nameArray.length; i++) {
      concludingChart.data.datasets.push({
        label: nameArray[i],
        fill: true,
        backgroundColor: COLOR_ARRAY[i],
        data: combinedValueArray[i],
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                max: 100
              },
              scaleLabel: {
                display: true,
                labelString: '% Share of Search',
                fontSize: 20,
              },
              stacked: true
            }]
          },
          plugins: {
            filler: {
              propagate: true
            }
          }
        },
      });
    }
    concludingChart.update();
    return(concludingChart);
  }

  return (
    <div className="Search">
      <SearchBar
        setClientInfo={setClientInfo}
        clientInfo={clientInfo}
        setSearching={setSearching}
        setChartSubTitle={setChartSubTitle}
        setSearchInitiated={setSearchInitiated}
        createMultiVolumeGraph={createMultiVolumeGraph}
        createMultiPercentGraph={createMultiPercentGraph}
        createMultiAreaGraph={createMultiAreaGraph}
      />
      {!searchInitiated && <HomeText />}
      {searchInitiated && (
        <div className="GraphContainer">
          {searching && <Loading />}
          <SosChart id="myVolumeChart" chartTitle="Trend in Search Volume" chartSubTitle={chartSubTitle} ref={volumeChartRef} />
          <SosChart id="myChart" chartTitle="Trend in % Share of Search" chartSubTitle={chartSubTitle} ref={chartRef} />
          <SosChart id="myAreaChart" chartTitle="Trend in % Share of Search" chartSubTitle={chartSubTitle} ref={areaChartRef} />
          <SosChart id="myBarChart" chartTitle="Total % Share of Search" chartSubTitle={chartSubTitle} ref={barChartRef} isDataError={isDataError} />
        </div>
      )}
    </div>
  );
}

export default Search;
