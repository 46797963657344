import React from 'react';
import './Css/Home.css';
import Nav from './Nav.js';
import About from './About.js';
import Search from './Search.js';
import Privacy from './Privacy.js';
import UsageInfo from './UsageInfo.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function Home() {
  window.analytics.page('Home');

  return (
      <Router>
        <Nav />
        <Switch>
          <Route path="/about"  component={About} />
          <Route path="/privacy"  component={Privacy} />
          <Route path="/usageinfo" component={UsageInfo} />
          <Route path="/"  component={Search} />
        </Switch>
      </Router>
  );
}

export default Home;
