import React, { Component } from 'react';
import './Css/Home.css';
import './Css/UsageInfo.css';
import {Link} from 'react-router-dom';
import $ from 'jquery';


class UsageInfo extends Component{

  totalSearchCount = 0;
  serverHost = process.env.REACT_APP_SERVER_HOST;

  componentDidMount() {
    this.getInfo(this);
  }

  getInfo = async e => {
    const response = await fetch(`${this.serverHost}/api/readInfo`);

    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    var countToDate = body.map[0].usage
    for(var n = countToDate.length -1; n>=0; n--){
      var item = countToDate[n];
      if(item.date != 0){
        this.totalSearchCount = this.totalSearchCount + 1;
        var date = new Date(item.date);
        var startDate = new Date(item.searchedForStartDate);
        var endDate = new Date(item.searchedForEndDate);
        date = date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear()+" &nbsp;"+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
        $("#usageDates").append(
          '<p id="date">Searched on: '+date+' | IP: '+item.ip+' | City: '+
          item.city+' | Region: '+item.region+'<br /> Terms: '+
          item.searchedFor+' | Country: '+item.searchedForCountry+
          ' | Category: '+item.searchedForCategory+
          ' | Start: '+startDate.getDate()+"-"+(startDate.getMonth()+1)+"-"+startDate.getFullYear()+
          ' | End: '+endDate.getDate()+"-"+(endDate.getMonth()+1)+"-"+endDate.getFullYear()+
          '</p><br />'
        )
      }
    }
    $("#usageTotal").append(
      '<p id="totalSearchCount">Total Search Count: '+this.totalSearchCount+'</p>')
    if(countToDate[countToDate.length -1].date != 0){
      var date = new Date(countToDate[countToDate.length -1].date)
      date = date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear()+" &nbsp;"+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
      $("#usageTotal").append(
      '<p id="date">Most Recent Search: '+date+' | IP: '+countToDate[countToDate.length -1].ip+' | City: '+countToDate[countToDate.length -1].city+' | Region: '+countToDate[countToDate.length -1].region+' | Searched For: '+countToDate[countToDate.length -1].searchedFor+'</p>'+
      '<br />');
    };
  };


  render() {
    return(
      <div className="UsageInfo">
        <div id="usageInfoContainer">
          <h1 id="usageInfoTitleText">Usage Info</h1>
          <br />
          <div id="usageTotal">
          </div>
          <div id="usageDates">
          </div>
        </div>
        <br /><br /><br />
        <div className="Footer">
          <div id="subFooter">
          <p>COPYRIGHT © 2021 CROFT ANALYTICS LTD. ALL RIGHTS RESERVED</p>
          <Link to='/' style={{ textDecoration: 'none' }} id="footerHomeLink">
            <p id="footerHomeText">Home &nbsp;</p>
          </Link>
          <Link to='/about' style={{ textDecoration: 'none' }} id="footerAboutLink">
            <p id="footerAboutText">&nbsp; About &nbsp;</p>
          </Link>
          <Link to='/search' style={{ textDecoration: 'none' }} id="footerSearchLink">
            <p id="footerSearchText">&nbsp; Search &nbsp;</p>
          </Link>
          <Link to='/privacy' style={{ textDecoration: 'none' }} id="footerPrivacyLink">
            <p id="footerPrivacyText">&nbsp; Privacy Policy</p>
          </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default UsageInfo;
