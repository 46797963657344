import React from 'react';
import PropTypes from 'prop-types';

/**
 * Data charts rendered from charts.js.
 *
 * @param {object} props
 * @param {string} props.id - Id used by charts.js to identify where to render data
 * @param {string} props.chartTitle
 * @param {string} props.chartSubTitle
 * @param {boolean} props.isDataError
 *
 * @returns {React.Component}
 */
const Chart = React.forwardRef(({ id, chartTitle, chartSubTitle, isDataError }, ref) => (
  <>
    <p className="chartTitles">{chartTitle}</p>
    <p className="chartSubTitles">
      {chartSubTitle}
      {isDataError && (<><br /><b style={{color: 'red'}}>DATA NOT AVAILABLE FOR ALL BRANDS FOR THE WHOLE PERIOD</b></>)}
    </p>
    <canvas
      width="800"
      height="400"
      id={id}
      ref={ref}
    />
  </>
));

Chart.propTypes = {
  id: PropTypes.string,
  chartTitle: PropTypes.string,
  chartSubTitle: PropTypes.string,
  isDataError: PropTypes.bool,
}

export default Chart;
