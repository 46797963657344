import React from 'react';
import './Css/Home.css';
import './Css/About.css';
import {Link} from 'react-router-dom';



function About() {
  document.title = "Share Of Search";
  return (
    <div className="About">
      <div id="aboutText">
        <h1 id="aboutTitleText">Why share of search is a useful metric</h1>
        <p id="aboutBaseText">Analysis of Google search data shows that, for many categories, each brand’s share of category search can be used to predict its market share.

        <br /><br />The use of share of search data to predict market shares particularly applies where search is a key activity in the process of deciding what to buy, for example in (but not limited to) automotive, finance, retail, telecoms, travel, and electronic goods categories. Here are links to files on this topic:
        </p>

        <ul id="filesBulletpoints">
          <li id="bulletpoint"><b>Presentation: </b><a href="https://ipa.co.uk/media/9669/james-hankins-effworks-share-of-search-presentation.pdf">The most important metric you’ve never heard of</a>, James Hankins, Nov 2020</li>
          <li id="bulletpoint"><b>Article: </b><a href="https://theeqplanner.wordpress.com/2020/08/07/the-most-important-metric-youve-never-heard-of/">The most important metric you’ve never heard of</a>, James Hankins, Aug 2020</li>
          <li id="bulletpoint"><b>Video: </b><a href="https://vimeo.com/465319431">Share of Search as a Predictive Measure</a>, Les Binet, Nov 2020</li>
          <li id="bulletpoint"><b>Presentation: </b><a href="https://ipa.co.uk/media/9566/effworks-2020-share-of-search-as-a-predictive-measure.pdf">A New Way To Track Brands and Advertising</a>, Les Binet, Aug 2020</li>
          <li id="bulletpoint"><b>Video: </b><a href="https://vimeo.com/481635584">A New Way To Track Consumer Demand</a>, Les Binet, Nov 2020</li>
          <li id="bulletpoint"><b>Article: </b><a href="https://www.warc.com/newsandopinion/news/why-marketers-should-embrace-share-of-search-as-a-metric/43942">Why marketers should embrace Share of Search as a metric</a>, WARC, Aug 2020</li>
          <li id="bulletpoint"><b>Article: </b><a href="https://www.kantar.com/uki/inspiration/advertising-media/share-of-search-your-moment-has-arrived/">Share of Search, your moment has arrived</a>, Kantar, Oct 2020</li>
          <li id="bulletpoint"><b>Article: </b><a href="https://www.marketingweek.com/mark-ritson-share-of-search-share-of-voice/">It’s time for ‘share of search’ to replace ‘share of voice’</a>, Mark Ritson, Sep 2020</li>
        </ul>

      </div>
      <br /><br /><br />
      <div className="Footer">
          <div id="subFooter">
          <p>COPYRIGHT © 2021 UPWAVE. ALL RIGHTS RESERVED</p>
          </div>
      </div>
    </div>
  );
}

export default About;
