import React from 'react';

/**
 * Text to display on home search before a search is initiated.
 *
 * @returns {React.Component}
 */
const HomeText = () => (
  <>
    <div id='homeText'>
      { /* Overview Section */  }
      <h1 id="homeTitleText">Overview</h1>
      <p id="homeBaseText">In many categories, share of category search volume can be used to predict a brand’s market share.</p>
      <p id="homeBaseText">Use this app to chart trends in Google search volumes and share of search for up to 40 search terms (e.g. brand names) from 2004 through today. You may filter by country, category, and date range.</p>

      { /* User Guide Section */  }
      <h1 id="homeTitleText">Guide</h1>
      <br />
      <ol id="filterBulletpoints"><br />
        <li id="bulletpoint">Select a <b><i>Country</i></b> from the drop down list.</li>
        <li id="bulletpoint">Select a relevant <b><i>Macro Category</i></b> or <b><i>Sub-Category</i></b> from the drop down list. For example, if you are looking at beer brands select ‘FOOD & DRINK / Alcoholic Beverages’.</li>
        <li id="bulletpoint">Select a <b><i>Start date</i></b> and <b><i>End date</i></b>. Periods of up to 38 weeks will display daily data in the charts, 40 - 269 weeks show weekly data, and 270+ weeks show monthly data.</li>
        <li id="bulletpoint">Enter <b><i>Search Terms</i></b> as a list of up to 40 terms (e.g. brands in a category). To build the list, enter each search term then press the [+] button. Alternatively, enter all the terms with a comma between each term.</li>
        <li id="bulletpoint">Click on the <b>SUBMIT</b> button to retrieve the data and generate the charts.</li>
      </ol>
      <div id="homeBaseBottomText">
        <p>Questions? Contact <a href="mailto:support@upwave.com">support@upwave.com</a>.</p>
        <p>Learn more about the leading brand analytics platform at <a href="https://www.upwave.com">http://www.upwave.com</a>. </p>
      </div>
    </div>
    <div id="downloadContainer"></div>
  </>
);

export default HomeText;
