export const CURRENT_YEAR = new Date().getFullYear();

// earliest date year we can perform trends search
export const START_YEAR = 2004;

// Number range representing years (ie, [2004..2021])
export const YEARS = Array(CURRENT_YEAR - START_YEAR + 1).fill().map((_, idx) => START_YEAR + idx);

// Number range representing days starting with 1 (ie, [1..31])
export const DAYS = Array(31).fill().map((_, idx) => 1 + idx);

// Number range representing months starting with 0 (ie, [0..11])
export const MONTHS_RANGE = Array(12).fill().map((_, x) => x);

export const MONTHS = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
