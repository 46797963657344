import './Css/Home.css';
import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/upwave.svg';
import homeIcon from '../images/homeIcon.png';
import searchIcon from '../images/searchIcon.png';
import aboutIcon from '../images/aboutIcon.png';


function Nav() {

  return (
    <nav>
      <a id="upwaveLink" href="https://www.upwave.com">
        <img src={logo} alt="Logo" id="logo-svg" />
      </a>
    </nav>
  );
}

export default Nav;
