import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  CURRENT_YEAR,
  START_YEAR,
  YEARS,
  DAYS,
  MONTHS_RANGE,
  MONTHS,
  SERVER_HOST
} from './constants';

/**
 * Search bar that manages search filters and initiates search on the search page.
 *
 * @param {object} props
 * @param {Function} props.createMultiAreaGraph - Function to generate graph with data returned from google trends api
 * @param {Function} props.createMultiPercentGraph - Function to generate graph with data returned from google trends api
 * @param {Function} props.createMultiVolumeGraph - Function to generate graph with data returned from google trends api
 * @param {Function} props.setClientInfo - update clientInfo state
 * @param {Function} props.setSearching - update searching state
 * @param {Function} props.setChartSubTitle - set chart subtitles to be passed in chart components
 * @param {any[]} props.clientInfo
 *
 * @returns {React.Component}
 */
const SearchBar = ({
  createMultiAreaGraph,
  createMultiPercentGraph,
  createMultiVolumeGraph,
  setClientInfo,
  setSearching,
  setChartSubTitle,
  setSearchInitiated,
  clientInfo,
}) => {
  const [countries, setCountries] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoriesMap, setCategoriesMap] = useState([]);
  const [searchTerms, setSearchTerms] = useState([]);

  const [country, setCountry] = useState();
  const [category, setCategory] = useState();
  const [startDateDay, setStartDateDay] = useState(1);
  const [startDateMonth, setStartDateMonth] = useState(0);
  const [startDateYear, setStartDateYear] = useState(START_YEAR);
  const [endDateDay, setEndDateDay] = useState(new Date().getDate());
  const [endDateMonth, setEndDateMonth] = useState(new Date().getMonth());
  const [endDateYear, setEndDateYear] = useState(CURRENT_YEAR);

  /**
   * Fetch countries and categories to populate the filters. Passes an empty dependency array to only run and clean up once.
   */
  useEffect(() => {
    getCountries().then(countries => {
      setCountries(Object.fromEntries(countries.map(([a, b]) => [b, a])));
    });
    getCategories().then(categories => {
      setCategories(categories);
      setCategoriesMap(Object.fromEntries(categories.map(([a, b]) => [b, a])));
    });
  }, []);

  /**
   * Get countries for menu options.
   *
   * @returns {object[]} name and id of countries
   */
  const getCountries = async () => {
    const response = await fetch(`${SERVER_HOST}/api/countriesjson`);

    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body.map;
  }

  /**
   * Get categories for menu options.
   *
   * @returns {object[]} name and id of categories
   */
  const getCategories = async () => {
    const response = await fetch(`${SERVER_HOST}/api/categoriesjson`);

    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body.map;
  }

  /**
   * Get name of month from Date.getMonth.
   *
   * @param {number} int number representation of month
   * @returns {string} name of month
   */
  const getCurrentEndDateMonth = (int) => {
    return MONTHS[int];
  }

  /**
   * Set search terms on state.
   *
   * @param {Event} e submit event
   */
  const addSearchWordsToStatePost = (e) => {
    e.preventDefault();

    setSearchTerms([
      ...searchTerms,
      e.target[0].value
    ]);

    e.target[0].value = '';
  }

  /**
   * Reset active search terms.
   *
   * @param {Event} e click event
   */
  const resetSearchWords = (e) => {
    e.preventDefault();

    setSearchTerms([]);
  }

  /**
   * Submit filters and search terms.
   *
   * @param {Event} e click event
   */
  const submitHandler = async e => {
    e.preventDefault();

    setSearching(true);
    setSearchInitiated(true);

    if (!searchTerms.length) {
      window.alert('Please enter at least one search term');
      window.location.reload();
    }

    const post = [
      country,
      new Date(startDateYear, startDateMonth, startDateDay),
      new Date(endDateYear, endDateMonth, endDateDay),
      category,
      null,
      searchTerms.join(', '),
    ];

    resetCharts();

    const response = await fetch(`${SERVER_HOST}/api/multiIOT`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post }),
    });
    const body = await response.json();
    const responseArrayVolume = body.mapVolume;

    let a = clientInfo;
    a[3] = (post[5]); //SearchTerms
    a[4] = (post[0]); //SearchCountry
    a[5] = (post[3]); //SearchCategory
    a[6] = (post[1]); //SearchStartDate
    a[7] = (post[2]); //SearchEndDate
    setClientInfo(a);

    await writeToFile();

    var dateArrayVolume = [];
    var valuesArrayVolume = [];
    var valuesArrayVolumeCheck = [];
    responseArrayVolume.forEach((item, i) => {
      dateArrayVolume.push(item[0]);
      valuesArrayVolume.push(item[1]);
      valuesArrayVolumeCheck.push(item[1]);
    });
    if(dateArrayVolume.length === 0 || valuesArrayVolumeCheck.length === 0 || valuesArrayVolume.length === 0){
      alert("Sorry there has been an error. This may be due to a far too restricted/small or far too large (greater than 40 queries) search or an issue with the Google API response.");
      window.location.reload();
    }

    createMultiVolumeGraph(dateArrayVolume, valuesArrayVolumeCheck, post);
    createMultiPercentGraph(dateArrayVolume, valuesArrayVolume, post);
    createMultiAreaGraph(dateArrayVolume, valuesArrayVolume, post);

    setSearching(false);
  };

  /**
   * Set subtitles on charts with filter data.
   */
  const resetCharts = () => {
    const titleCountry = countries[country] || 'worldwide';
    const titleCategory = categoriesMap[category] || 'all';
    const chartSubTitleString = titleCountry.toUpperCase() + " | " + titleCategory.toUpperCase() +
    " | " + startDateDay + " " + (MONTHS[startDateMonth].substring(0,3).toUpperCase()) + " " + startDateYear +
    " - " + endDateDay + " " + (MONTHS[endDateMonth].substring(0,3).toUpperCase()) + " " + endDateYear;
    setChartSubTitle(chartSubTitleString);
  }

  const writeToFile = async e => {
    await fetch(`${SERVER_HOST}/api/writeinfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: clientInfo }),
    });
  }

  return (
    <div id="leftSearchColumn">
      <div id="filters">
        <h1 id="searchPageTitle">Select Search Criteria</h1>

        <form id="countriesForm">
          <span><p id="countryText">Country </p></span>
          <br />
          <select name="countries" id="countries" onChange={(e) => setCountry(e.target.value)}>
            <option value="">Worldwide</option>
            {Object.entries(countries).map(country => {
              const [countryCode, countryName] = country;
              return (
                <option key={countryCode} value={countryCode} id="countryList">{countryName}</option>
              );
            })}
          </select>
        </form>
        <span id="selectedCountry"></span>
        <form id="categoriesForm">
          <span><p id="categoryText">Category</p></span>
          <br />
          <select name="categories" id="categories" onChange={(e) => setCategory(e.target.value)}>
            <option value="0">All</option>
            {categories.map(category => {
              const [categoryName, categoryId] = category;
              const isCategoryHeader = categoryName.slice(-1) === categoryName.slice(-1).toUpperCase();
              return (
                <>
                  {isCategoryHeader && <optgroup key={`${categoryId}-optgroup`} label="-----"></optgroup>}
                  <option key={categoryId} value={categoryId} id={`categoriesList${isCategoryHeader ? 'Header' : ''}`}>{categoryName}</option>
                </>
              );
            })}
          </select>
        </form>
        <span id="selectedCategory"></span>

        <div id="dateRange">
          <form id="startDateForm">
            <span><p id="startText">Start </p></span><br />
            <select name="day" id="startDay" onChange={(e) => setStartDateDay(e.target.value)}>
              {DAYS.map(day => (
                <option key={`${day}-startDay`} value={day}>{day}</option>
              ))}
            </select>
            <select name="month" id="startMonth" onChange={(e) => setStartDateMonth(e.target.value)}>
              {MONTHS_RANGE.map(month => (
                <option key={`${month}-startMonth`} value={month}>{MONTHS[month]}</option>
              ))}
            </select>
            <select name="year" id="startYear" onChange={(e) => setStartDateYear(e.target.value)}>
              {YEARS.map(year => (
                <option key={`${year}-startYear`} value={year}>{year}</option>
              ))}
            </select>
          </form>
          <br />
          <form id="endDateForm">
            <span><p id="endText">End </p></span><br />
            <select name="day" id="endDay" onChange={(e) => setEndDateDay(e.target.value)}>
              <option key='todayDay' value={new Date().getDate()}>{new Date().getDate()}</option>
              {DAYS.map(day => (
                <option key={`${day}-endDay`} value={day}>{day}</option>
              ))}
            </select>
            <select name="month" id="endMonth" onChange={(e) => setEndDateMonth(e.target.value)}>
              <option key='todayMonth' value={new Date().getMonth()+1}>{getCurrentEndDateMonth(new Date().getMonth())}</option>
              {MONTHS_RANGE.map(month => (
                <option key={`${month}-endMonth`} value={month}>{MONTHS[month]}</option>
              ))}
            </select>
            <select name="year" id="endYear" onChange={(e) => setEndDateYear(e.target.value)}>
              <option key='todayYear' value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
              {YEARS.map(year => (
                <option key={`${year}-endYear`} value={year}>{year}</option>
              ))}
            </select>
          </form>
          <p id="selectedStartDate"></p>
          <p id="selectedEndDate"></p>
        </div>

        <form onSubmit={addSearchWordsToStatePost}>
          <p id="searchListText">Search List</p><br />
          <input
            id="multiSubmitInput"
            type="text"
            defaultValue=""
          />
          <button type="submit">+</button>
          <button onClick={resetSearchWords}>Clear</button>
          <p id="infoSearch">i</p>
          <div id="infoSearchText">
            For each search term (eg. a brand name), enter the term then click the [+] button to build the list. The list appears below the box. <br /><br />
            OR enter multiple terms with a comma between each term in the box then click [+]. <br /><br />
            A search term can include any number of variants of the term by entering ‘+’ between each variant (eg. VW+Volkswagen). You can also use '-' to exclude a term (eg. VW-Volkswagen will exclude results containing Volkswagen) <br /><br />
          </div>
          <p id="searchListExplanationText">For each search term, input the term then click the [+] button to add it to the list</p>
        </form>
        <span><p id="searchingFor">{searchTerms.join(', ')}</p></span><br />

        { false && (
          <div class="graphSlider">
            <p id="graphSizeText">Graph Size</p>
            <input type="range" min="700" max="1800"
              defaultValue={this.sliderValue}
              className="slider" id="myRange"
              onChange={e => this.graphSliderHandler(e)}
            />
          </div>
        )}
        <br />

        <button type="submit" id="criteriaSubmitButton" onClick={submitHandler}>SUBMIT</button>
      </div>
      <p id="sourceText"><i>Source of search data: Google Trends</i></p>
    </div>
  );
}

SearchBar.propTypes = {
  createMultiAreaGraph: PropTypes.func,
  createMultiPercentGraph: PropTypes.func,
  createMultiVolumeGraph: PropTypes.func,
  setClientInfo: PropTypes.func,
  setSearching: PropTypes.func,
  setChartSubTitle: PropTypes.func,
  setSearchInitiated: PropTypes.func,
  clientInfo: PropTypes.array,
}

export default SearchBar;
