import React from 'react';
import './Css/Home.css';
import './Css/Privacy.css';
import {Link} from 'react-router-dom';



function Privacy() {
  document.title = "Share Of Search";
  return (
    <div className="Privacy">
      <div id="privacyText">
      <h1 id="privacyTitleText">Privacy Policy</h1>
      <p id="privacyBaseText">The Croft Analytics Share-Of-Search app does not store any personal information. There is no linked database or any storage method.
      <br /><br />No account creation is required and any searches made within the app are not stored or tracked.
      <br /><br />Croft Analytics does not own any of the data provided within the app, just the code used to make the app function.
      <br /><br />Users are free to use the app however they like.
      <br /><br /><br />For more information about Share-Of-Search or Croft Analytics please email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a>
      </p>
      </div>
      <br /><br /><br />
      <div className="Footer">
        <div id="subFooter">
        <p>COPYRIGHT © 2021 CROFT ANALYTICS LTD. ALL RIGHTS RESERVED</p>
        <Link to='/about' style={{ textDecoration: 'none' }} id="footerAboutLink">
          <p id="footerAboutText">&nbsp; About &nbsp;</p>
        </Link>
        <Link to='/' style={{ textDecoration: 'none' }} id="footerSearchLink">
          <p id="footerSearchText">&nbsp; Search &nbsp;</p>
        </Link>
        <Link to='/privacy' style={{ textDecoration: 'none' }} id="footerPrivacyLink">
          <p id="footerPrivacyText">&nbsp; Privacy Policy</p>
        </Link>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
