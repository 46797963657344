class ShareOfSearchRetrieval{
  static getShareOfSearchPercentage(nameArray, valuesArray){
    var combinedValueArray = new Array(valuesArray[0].split(",").length);
    var searchVolumeArray = new Array(valuesArray[0].split(",").length);
    var percentageShareArray = new Array(valuesArray[0].split(",").length);
    var grandTotal = 0
    var hundredName = "";
    for(var i = 0; i < nameArray.length; i++){
      nameArray[i] = nameArray[i].trim()
    }
      var namesArray = [];
      nameArray.forEach((item, i) => {
        namesArray.push(item);
      });

      for(var x = 0; x < combinedValueArray.length; x++){
        combinedValueArray[x] = new Array();
      }
      for(var x = 0; x < searchVolumeArray.length; x++){
        searchVolumeArray[x] = new Array();
      }
      for(var x = 0; x < percentageShareArray.length; x++){
        searchVolumeArray[x] = new Array();
      }

      valuesArray.forEach((item, i) => {
        var vArray = item.split(",");
          for (var c = 0; c < vArray.length; c++) {
            combinedValueArray[c].push(vArray[c]);
            if(vArray[c] == 100){hundredName = namesArray[c]}
          }
      });

      for(var c = 0; c<combinedValueArray.length; c++){
        combinedValueArray[c].forEach((item, i) => {
          if(typeof searchVolumeArray[c] == "string"){
            searchVolumeArray[c] = 0
          }
          item = parseInt(item);
          searchVolumeArray[c] = searchVolumeArray[c] + item;
        });
      }

      searchVolumeArray.forEach((item, i) => {
        grandTotal += item;
      });


      for(var e = 0; e<searchVolumeArray.length; e++){
        percentageShareArray[e] = (searchVolumeArray[e]/grandTotal) * 100;
      }

      var totalShareString = "Total share of searches are as follows: \n";
      for(var x = 0; x<percentageShareArray.length; x++){
        totalShareString = totalShareString.concat(" " + namesArray[x] + " : "
         + percentageShareArray[x].toFixed(2) + "\n")
      }
      totalShareString = totalShareString.concat(" Repeated Brand: " + hundredName);
      return(totalShareString);
  }
}

export default ShareOfSearchRetrieval;
