import React from 'react';

/**
 * Generic loading spinner.
 *
 * @returns {React.Component}
 */
const Loading = () => (
  <>
    <div id='loader'></div>
    <p id="loadingDataText" style={{fontWeight: 'normal'}}>Retrieving data, please wait...</p>
  </>
);

export default Loading;
